import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'
import SEO from '../components/seo'
import Header from '../components/header'
import Sidebar from '../components/sidebar'
import { FaChevronCircleUp } from 'react-icons/fa'
import '../css/layout.scss'
import '../css/manual.scss'
import '../css/footer.scss'

class ManualPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chapters: [],
      scrolled: false,
      sticky: true,
      tree: [],
      updateHTML: true,
      showSidebar: false,
      light: false,
      background: 0,
    }
    this.toggleSidebar = this.toggleSidebar.bind(this)
  }

  componentDidMount() {
    const chapters = this.props.data.allMarkdownRemark.edges
    let tree = []
    _.forEach(chapters, chapter => {
      const { frontmatter, headings } = chapter.node
      tree.push({
        value: frontmatter.title,
        depth: 1,
        children: headings.length > 0 ? headings : [],
      })
    })
    const background = Math.floor(Math.random() * 4 + 1)
    this.setState(state => ({
      chapters,
      tree,
      light: background === 2,
      background,
    }))
  }

  componentDidUpdate() {
    if (this.state.updateHTML) {
      // set ids for headings level 2
      const allHeadings = document.querySelectorAll(
        '.chapter h1, .chapter h2, .chapter h3'
      )
      const allImages = document.querySelectorAll('.bodytext img')
      _.forEach(allImages, i => {
        i.parentNode.setAttribute(
          'class',
          i.alt === 'DIF Planning Phase' || i.alt === 'DIF Intervention Phase'
            ? 'half-width'
            : 'full-width'
        )
      })
      _.forEach(allHeadings, h => {
        if (h.tagName === 'H1') {
          h.setAttribute('id', _.kebabCase(h.innerText))
        } else {
          h.parentNode.setAttribute('id', _.kebabCase(h.innerText))
        }
      })
      this.setState({ updateHTML: false })

      const allTemplateLinks = document.querySelectorAll('.bodytext a')
      allTemplateLinks.forEach(a => {
        a.setAttribute('target', '_blank')
        a.setAttribute('rel', 'nofollow')
      })
    }
  }

  registerScroll() {
    Events.scrollEvent.register('begin', function(to, element) {
      // console.log("begin", arguments);
    })
    Events.scrollEvent.register('end', function(to, element) {
      // console.log("end", arguments);
    })
    scrollSpy.update()
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }

  scrollToTop() {
    scroll.scrollToTop()
  }

  handleSetActive(to) {
    console.log(to)
  }

  toggleSidebar(e) {
    e.preventDefault()
    this.setState({ showSidebar: !this.state.showSidebar })
  }

  render() {
    const {
      chapters,
      tree,
      showSidebar,
      background,
      sticky,
      light,
    } = this.state
    return (
      <div>
        <SEO title="DIF Manual" keywords={['DIF', 'Manual', 'dif-manual']} />
        <Header sticky={sticky} bg={`${light ? 'light' : 'dark'}`} />
        <div className="main">
          <FaChevronCircleUp
            className={`back-to-top ${light ? 'light' : ''}`}
            onClick={this.scrollToTop}
          />
          <Sidebar
            tree={tree}
            showSidebar={showSidebar}
            toggleSidebar={this.toggleSidebar}
            light={light}
          />

          <div
            className="content chapters"
            style={{
              backgroundImage: `url(/images/bg-${background}.png)`,
            }}
          >
            {_.map(chapters, (chapter, index) => {
              return (
                <div className="chapter" key={index}>
                  <h1>{chapter.node.frontmatter.title}</h1>
                  <div
                    className="bodytext"
                    dangerouslySetInnerHTML={{
                      __html: chapter.node.html,
                    }}
                  />
                </div>
              )
            })}
            <div className="footer">
              <div className="contact-info w-90 w-70-l">
                We'd love to read what you think about the DIF Manual. Please do
                take a few moments send us your comments or feedback at{' '}
                <span className="e-add">gro.srednefedlatigid@slaunam</span>{' '}
                <span className="flat">
                  (You can use this <a href="https://www.digitaldefenders.org/wp-content/uploads/team.pub.txt">PGP key</a> - Fingerprint: 76A0 260E F1F6 2B34 8B8C E333 B5D2 5420 8C95 4101)
                </span>
                .
              </div>
              <a
                className="cc-lic"
                rel="license"
                target="_blank license nooener noreferrer"
                href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              >
                <img
                  alt="Creative Commons License"
                  style={{ borderWidth: '0', marginRight: '5px' }}
                  src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
                />
                This work is licensed under a Creative Commons
                Attribution-NonCommercial-ShareAlike 4.0 International License
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ManualPage
export const pageQuery = graphql`
  query indexQuery {
    allMarkdownRemark(
      sort: { fields: frontmatter___order }
      filter: {
        frontmatter: {
          collections: { eq: "manual" }
          publish: { eq: "publish" }
        }
      }
    ) {
      edges {
        node {
          headings {
            value
            depth
          }
          id
          html
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`
