import React from 'react'
import PropTypes from 'prop-types'

import '../css/header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggleSidebar = this.toggleSidebar.bind(this)
  }

  toggleSidebar() {}

  render() {
    return <div />
  }
}

Header.propTypes = {
  sticky: PropTypes.bool,
  siteTitle: PropTypes.string,
  bg: PropTypes.string,
}

Header.defaultProps = {
  sticky: true,
  siteTitle: 'Back',
}

export default Header
