import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-scroll'
import { FiMenu, FiX } from 'react-icons/fi'

import '../css/sidebar.scss'

class Sidebar extends React.Component {
  render() {
    const { tree, showSidebar, toggleSidebar, light } = this.props

    const RenderChildren = children => {
      if (children.length > 0) {
        return (
          <ul>
            {_.map(children, (e, i) => (
              <div key={i}>
                {e.depth <= 3 ? (
                  <li className={`level-${e.depth}`}>
                    <Link
                      to={`${_.kebabCase(e.value)}`}
                      spy={true}
                      smooth={true}
                      offset={-30}
                      duration={500}
                      hashSpy={true}
                      className={`pointer ${_.kebabCase(e.value)} ${e.depth}`}
                      onClick={e => this.props.toggleSidebar(e)}
                    >
                      {e.value}
                    </Link>
                  </li>
                ) : (
                  ''
                )}
              </div>
            ))}
          </ul>
        )
      }
    }
    return (
      <div className="sidebar w-30-l w-50-m w-80">
        {showSidebar ? (
          <FiX
            className={`menu close ${light ? 'light' : ''}`}
            onClick={e => toggleSidebar(e)}
          />
        ) : (
          <FiMenu
            className={`menu bars ${light ? 'light' : ''}`}
            onClick={e => toggleSidebar(e)}
          />
        )}
        <div
          className="sidebar-tree"
          style={{
            transform: `translateX(${showSidebar ? '0px' : '-600px'})`,
          }}
        >
          <ul>
            {_.map(tree, ({ value, depth, children }, idx) => (
              <li key={idx} className={`level-${depth}`}>
                <Link
                  to={`${_.kebabCase(value)}`}
                  spy={true}
                  smooth={true}
                  offset={-30}
                  duration={500}
                  hashSpy={true}
                  className={`pointer parent ${_.kebabCase(value)} ${depth}`}
                  onClick={e => this.props.toggleSidebar(e)}
                >
                  {value}
                </Link>
                {RenderChildren(children)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

Sidebar.propTypes = {
  tree: PropTypes.any,
  showSidebar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  light: PropTypes.bool,
}

export default Sidebar
